<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <h3 class="card-title">Filialbestellungen</h3>
                <div class="card-tools">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                <ul class="navbar-nav">
                                    <li class="nav-item mr-1">
                                        <div class="input-group input-group-sm" style="margin-top: 0;">
                                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadOrders" :value="search" @input="$store.commit('store_orders/changeSearch', $event.target.value)" />
                                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadOrders">
                                                <i class="fas fa-search"></i>
                                            </button>
                                            <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </li>
                                    <li class="nav-item d-none d-md-block">
                                        <router-link class="btn btn-primary btn-xs" :to="{name: 'store-orders.create'}" v-if="$auth.check('store.order.create')"><i class="fas fa-fw fa-plus-circle"></i> Neue Filialbestellung</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <th><input type="checkbox" v-model="selectAll" /></th>
                        <th>Filiale</th>
                        <th>
                            <a href="#" @click.prevent="changeSort('ordernumber')">Bestellnummer</a>
                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                        </th>
                        <th>Datum</th>
                        <th>Status</th>
                        <th>Aktion</th>
                    </thead>

                    <tbody>
                        <tr v-for="(order, index) in orders.data" :key="order.id">
                            <td><input type="checkbox" :value="order.id" v-model="selectedOrders"/></td>
                            <td>{{ order.store.name }} </td>
                            <td>{{ order.ordernumber }}</td>
                            <td>{{ order.created_at | formatDate}}</td>
                            <td>
                                <div v-if="selectedRow[index] && selectedCell === 'order_status'" class="input-group">
                                    <select class="form-control form-control-sm" v-model="orders.data[index].status.id" @change="changeStatus('order', index)" v-if="$auth.check('orders.edit')">
                                        <template v-for="state in orderStates">
                                            <option v-if="state.name != 'open'" :key="state.id" :value="state.id">{{ state.description }}</option> 
                                        </template>
                                    </select>
                                    <div class="input-group-append">
                                        <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('order', index)"><i class="fas fa-save"></i></button> -->
                                        <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'order_status')"><i class="fas fa-times"></i></button>
                                    </div>
                                </div>
                                <template v-else>
                                    <template v-if="checkStateEdit && order.status.name != 'open'">
                                        <span @dblclick="handleEditCell(index, 'order_status')" class="badge" :class="setOrderBadge(order.status.name)">{{ order.status.description }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="badge" :class="setOrderBadge(order.status.name)">{{ order.status.description }}</span>
                                    </template>
                                </template>
                            </td>
                            <td>
                                <!-- v-b-popover.hover.top="'bearbeiten'" -->
                                <button type="button" class="btn btn-primary btn-sm mr-1" @click="downloadPicklist(order)" v-if="order.blocked != 1"><i class="fas fa-download"></i></button>
                                <router-link class="btn mr-1 btn-success btn-sm" :to="{name: 'store-orders.show', params: {id: order.id}}" v-if="$auth.check('store.order.show')"><i class="fas fa-eye"></i></router-link>
                                <router-link  class="mr-1 btn btn-warning btn-sm" :to="{name: 'store-orders.details', params: {id: order.id}}" v-if="checkStoreEdit(order.store.id) && order.blocked != 1"><i class="fas fa-edit"></i></router-link>
                                <button class="mr-1 btn btn-danger btn-sm" @click="removeOrder(order.id)" v-if="$auth.check('store.order.destroy')"><i class="fas fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="card-footer">
                <template v-if="orders.data.length > 0">
                    <div class="row align-items-center">
                        <div class="col-md-9">
                            <pagination class="float-left" :data="orders" @pagination-change-page="loadOrders" :limit="3"></pagination>
                        </div>
                        <div class="col-md-2">
                            <span class="float-right">Anzeige Eintrag {{ orders.meta.from }} - {{ orders.meta.to }} von {{ orders.meta.total }}</span>
                        </div>
                        <div class="col-md-1">
                            <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                <option value="25">25 Artikel</option>
                                <option value="50">50 Artikel</option>
                                <option value="75">75 Artikel</option>
                                <option value="100">100 Artikel</option>
                                <option value="125">125 Artikel</option>
                                <option value="150">150 Artikel</option>
                            </select>
                        </div>
                    </div>                        
                </template>
            </div>
        </div>
    </b-container>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'supplier-orders.index',
    title: 'Filialbestellung',

    data() {
        return {
            'orders': {
                data: [],
            },
            selectedOrders: [],
            orderStates: {},
            billingStates: {},
            selectedRow: {},
            selectedCell: null,
            userStores: [],
            userRoles: [],
        }
    },

    computed:{
        selectAll: {
            get: function () {
                return this.orders.data ? this.selectedOrders.length == this.orders.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.orders.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedOrders = selected;
            }
        },
        ...mapState('store_orders', ['params', 'search', 'page'])
    },

    watch: {
        params: {
            handler () {
                this.loadOrders();
            },
            deep: true
        },
    },

    methods: {
        getUserRoles()
        {
            this.axios 
                .post("/store-orders/getUserRoles")
                .then((response) => {
                    this.userRoles = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        checkStateEdit()
        {
            let elem = this.userRoles.find(element => element.name == 'Lager');
            if(elem != null)
            {
                return true;
            } else
            return false;
        },
        resetSearch(){
            this.$store.commit('store_orders/changeSearch', '');
            this.loadOrders();
        },
        changeStatus(mode, order)
        {
            var orders = [this.orders.data[order].id];
            this.axios
                .post("/store-orders/changeStatus", {
                    orders: orders,
                    mode: mode,
                    status_id: this.orders.data[order].status.id,
                    print: 0
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Status erfolgreich geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.handleEditCell(order, mode + '_status')
                    this.loadOrders();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen1",
                    });
                });
        },
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        setOrderBadge(state){

            if(state == 'open')
            {
                return 'badge-danger'
            } else if(state == 'closed')
            {
                return 'badge-success'
            } else if(state == 'release')
            {
                return 'badge-warning'
            } else if(state == 'in_process')
            {
                return 'badge-secondary'
            }

            return 'badge-primary';
        },

        getOrderStates(){
            this.$Progress.start();
            this.axios
                .get("/states/storeOrder")
                .then((response) => {
                    this.orderStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        downloadPicklist(order)
        {
            this.axios
                .get("/store-orders/" + order.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    //this.orders = response.data.data;
                    this.downloadFile(response, order)
                    this.$Progress.finish();
                    this.loadOrders();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        downloadFile(response, order){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Bestellschein-' + order.ordernumber + '.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },
        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        loadOrders(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/store-orders",{
                    params:{
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.orders = response.data;
                    this.$store.commit('store_orders/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        getUserStores() {
            this.axios
                .get("/store-orders/pwa/stores")
                .then((response) => {
                    this.userStores = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    });
                });
        },
        checkStoreEdit(store = null)
        {
            let inStore = this.userStores.find(element => element.id == store);
            if(inStore != null)
            {
                return true;
            } else
            {
                return false;
            }
        },
        removeOrder(id)
        {
            this.axios
                .delete('/store-orders/' + id)
                .then(() => {
                    this.loadOrders();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        }
    },

    created() {
        this.getUserStores();
        this.getOrderStates();
        this.loadOrders();
        this.getUserRoles();
    }
}
</script>

<style>

</style>